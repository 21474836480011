import Vue from 'vue'
import Vuex from 'vuex'
import api from "../api/api_settings";

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    user: {
      status: 'asd'
    },
  },

  getters:{
    getUser(st){
      return st.user;
    }
  },

  mutations: {
    SET_USER(st, pl){
      st.user = pl;
    },
  },
  actions: {

    getUser({ commit }){

      api.get('login').then((r) => {

        if (r.status !== 200)
          return false;

        commit('SET_USER', r.data)
      })
    }
  },
  modules: {
  }
})
