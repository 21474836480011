import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import ApiPlugin from './plugins/api'

Vue.config.productionTip = false

Vue.use(ApiPlugin)

if(window.location.href.indexOf('127.0.0.1') === -1 && window.location.href.indexOf('localhost') === -1) {
  Sentry.init({
    Vue,
    dsn: "https://173af8941293467b818a30bcf4b19c40@sentry.inta.tv/5",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

}
// ...

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
