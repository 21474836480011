<template>
  <v-app style="min-height: 45px">


<!--    <v-snackbar
      v-model="snackbar.visible"
      timeout="-1"
    >
      <v-row>
        <span>{{ (fileForm.file !== null ? fileForm.file.name: 'no file') }}</span>
        <v-progress-linear :value="snackbar.process"></v-progress-linear>
      </v-row>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar.visible = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>-->

 <v-dialog
      v-model="addFileDialog"
      persistent
      max-width="600px"
    >

   <v-overlay
       v-if="snackbar.visible"
   >
             <v-progress-circular
                 :size="70"
                 :width="3"
                 color="white"
                :value="snackbar.process"
              >
                {{ snackbar.process }}
              </v-progress-circular>
   </v-overlay>

   <v-alert
        dense
        type="error"
        v-if="error_massage !== ''"
    >{{ error_massage }}</v-alert>

   <v-card>

     <v-card-title>Добовление файла</v-card-title>

    <form id="fileForm">
         <v-file-input
    label="Видео в формате mp4"
    v-model="fileForm.file"
    locale="ru-RU"
    counter
    accept="video/mp4"
    show-size
    outlined
    @change="getTime"
    prepend-icon="mdi-paperclip"
  >
    <template v-slot:selection="{ text }">
      <v-chip
        small
        label
        color="primary"
      >
        {{ text }}
      </v-chip>
    </template>
  </v-file-input>

       <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="true"

        transition="scale-transition"

        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fileForm.startDate"
            label="Дата"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            :error-messages="dateError"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="fileForm.startDate"
          locale="ru-RU"
          no-title
          scrollable

          @input="$refs.menu.save(fileForm.startDate)"
        >
        </v-date-picker>


      </v-menu>

      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="fileForm.startTime"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fileForm.startTime"
            label="Время"
            prepend-icon="mdi-clock-time-four-outline"
            :messages="end_time"
            :error-messages="timeError !== '' ? timeError:busy_error"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          format="24hr"
          locale="ru-RU"
          v-model="fileForm.startTime"
          full-width
          @click:minute="$refs.menu.save(fileForm.startTime)"
        ></v-time-picker>
      </v-menu>
      </form>

     <v-divider></v-divider>

      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('dialogEvent')"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="upload_file"
            :disabled="validationForm === true"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
let today = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
export default {
  name: "AddFileForm",

  data: () =>({
      menu: false,
      menu2: false,

    error_massage: '',

    snackbar:{
      visible: false,
      text: '',
      timeout: 2000,
      process: 0
    },

    fileForm:{
      file: null,
      fileLive: -1,
      fileTime: 0,
      startTime: null,
      startDate: today,
      csrfError: false,
    }
  }),

  computed:{
    busy_error(){
      let err = ''


      if(this.fileForm.startTime !== null) {
        let date_time = new Date(this.fileForm.startDate + 'T' + this.fileForm.startTime);

        this.events.forEach(el => {

          if (el.start <= date_time && el.end >= date_time)
            err = 'Вермя занято!'
        })
      }
      return err;
    },

    dateError(){
      if(this.fileForm.startDate < today) {
        return 'Дата уже прошла';
      }

      return ''
    },
    timeError(){

      if(this.fileForm.startDate == today && this.fileForm.startTime !== null){
        let time = new Date(Date.now());
        let st_time = this.fileForm.startTime.split(':');


        if(parseInt(st_time[0]) < time.getHours() || (parseInt(st_time[0]) === time.getHours() && parseInt(st_time[1]) < time.getMinutes())) {
          return 'Время уже прошло!';
        }
      }


      return ''
    },
    validationForm(){

      return this.fileForm.file === null || this.fileForm.startTime === null || this.dateError !== ''
          || this.timeError !== ''|| this.busy_error !== '';


    },

    end_time() {
      if (this.fileForm.startTime !== null) {

        let time = this.fileForm.startTime.split(':');
        time[0] = parseInt(time[0])
        time[1] = parseInt(time[1])

        let h = Math.floor(this.fileForm.fileTime / 60 / 60);
        var m = Math.floor(this.fileForm.fileTime / 60) - (h * 60);


        time[1] = time[1] + m + ( (time[1] + m > 60 && (h+=1))? -60: 0)
        time[0] = time[0] + h + (time[0] + h > 24 ? -24: 0)
        time[1] = time[1]<10 ? '0'+time[1]: time[1];

        return 'Трансляция будет длиться до ' + time.join(':');
      }

      return '';
    }
  },

  methods: {
    getTime(){
      if(this.fileForm.file == null){
        this.fileForm.fileTime = 0;

        return false;
      }

      var vid = document.createElement('video');

      vid.src = URL.createObjectURL(this.fileForm.file);

      let fileFormUrl = this.fileForm;

      vid.ondurationchange = function() {

           fileFormUrl.fileTime = this.duration;
      };
    },

    upload_file(){

      this.error_massage = '';
      this.snackbar.process = 0;


        let fd = new FormData();
        fd.append('video', this.fileForm.file)
        fd.append('date_start', this.fileForm.startDate)
        fd.append('time_start', this.fileForm.startTime)

        this.snackbar.visible = true;

        this.$api.post('file',fd,{
          headers:{
                    'Content-Type': 'multipart/form-data',
                  },

          onUploadProgress: pe => {
            this.snackbar.process = Math.round((pe.loaded*100)/pe.total)
          }
        }).then(r => {
          this.snackbar.visible = false;

          if(r.status === 200){

            if(r.data.status === true){
                    this.$emit('addEvent', r.data);
                    this.$emit('dialogEvent');

                    this.fileForm.file = null;
                    this.fileForm.startTime = null;
                    this.fileForm.startDate = today;
            }else{
              if(r.data.error === 1)
                this.error_massage = 'Не удалось распознать формат файла!';

              if (r.data.error === 2)
                this.error_massage = 'В это время уже идёт трансляция, поменйте время.';

              if (r.data.error === 3)
                this.error_massage = 'Дата уже прошла!';
              if (r.data.error === 4)
                this.error_massage = 'Разрешение видео превышает 1080p!';
            }
          }
        })

    }
  },

  props: ['addFileDialog', 'date', 'events'],

  watch:{
    date: function (n){
      if(n !== '')
        this.fileForm.startDate = n;
      else
        this.fileForm.startDate = today;
    }
  }
}
</script>

<style scoped lang="scss">

  #addButton{
    padding-top: 5px;
  }

  #fileForm{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

</style>