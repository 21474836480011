<template>
  <v-app>
    <ControlPannel v-if="user.status === true"></ControlPannel>
    <AuthForm v-if="user.status === false"></AuthForm>
  </v-app>
</template>

<script>
import AuthForm from "../components/AuthForm";
import ControlPannel from "../components/ControlPannel";

  export default {


    name: 'Home',

    computed:{
      user(){
        return this.$store.getters.getUser
      }
    },

    components: {
      AuthForm,
      ControlPannel
    },
  }
</script>