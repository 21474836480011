import axios from "axios";

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const djangoURL = window.location.href.indexOf('127.0.0.1') !== -1 ? window.location.href.slice(0, -5)+'8000/' : window.location.href;



const api = axios.create({
    baseURL: djangoURL,
    withCredentials: true,
    headers:{
        accept: 'application/json',
        'X-CSRFToken': getCookie('csrftoken')
    },
})

export default api