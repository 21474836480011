<template>
  <v-app id="auth">

    <form @submit.prevent="auth_login">

      <v-img id="logo"
          src="../assets/logo.png"
          max-width="200px"
      ></v-img>

      <div id="error_massage"> {{ error_massage }} </div>

      <v-text-field
        label="Логин"
        v-model="formData.login"
        @input="loginFocus = true"
        :error-messages="loginFocus ? loginErrors : ''"
        outlined
      ></v-text-field>



      <v-text-field
        label="Пароль"
        type="password"
        v-model="formData.password"
        @input="passwordFocus=true"
        :error-messages="passwordFocus ? passwordErrors : ''"
        outlined
      ></v-text-field>


      <v-btn
      type="submit"
      plain
      color="#1976d2"
      :disabled="loginErrors !== '' || passwordErrors !== ''"
      >Войти</v-btn>

      <v-overlay v-if="formLoading">
            <v-progress-circular
      :size="70"
      :width="3"
      color="white"
      indeterminate
    ></v-progress-circular>
         </v-overlay>
    </form>
  </v-app>
</template>

<script>

export default {
  name: "AuthForm",
  data: () =>({
    loginFocus: false,
    passwordFocus: false,

    formLoading:false,

    error_massage: '',

    formData: {
      login: '',
      password: ''
    }
  }),
  computed:{
    loginErrors(){
      if (this.formData.login === '')
        return 'Поле логина не может быть пустым'

      if (this.formData.login.length < 3)
        return 'Слишком короткий логин'

      return ''
    },

    passwordErrors(){
      if (this.formData.password === '')
        return 'Поле пароля не может быть пустым'

      if (this.formData.password.length < 3)
        return 'Слишком короткий пароль'

      return ''
    }
  },

  methods:{
      auth_login(){
        if (this.formLoading)
          return false

        this.formLoading = true

        this.$api.post('login', this.formData).then((r) => {
           if(r.status === 200 && r.data.status)
             location.reload();
           else
             this.error_massage = 'Неправильный логин или пароль!';

           this.formLoading = false
        });
      }
  },
  components:{

  }
}
</script>

<style scoped lang="scss">
  #auth{
    width: 100%;
    height: 100%;
    background-color: #857f7f;

    form {
      background-color: white;

      margin-right: auto;
      margin-left: auto;
      display: block;
      vertical-align: middle;
      width: 400px;
      z-index: 1;

      padding: 8px 50px 8px 50px;
      border-radius: 10px;
      box-shadow: 1px 3px 9px -2px black;

      position: absolute;
      top: 48%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      button{
        display: block;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
      }

      #logo{
        margin-left: auto;
        margin-right: auto;
      }

      #error_massage{
        color: red;
        text-align: center;
        height: 35px;
      }
    }
  }
</style>