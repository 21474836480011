<template>
  <v-app id="content">
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="deleteFileForm.show"
      v-if="deleteFileForm.show"
    >
      <v-overlay v-if="deleteFileForm.loading">
            <v-progress-circular
      :size="70"
      :width="3"
      color="white"
      indeterminate
    ></v-progress-circular>
         </v-overlay>

      <v-card>
        <v-card-text>
          <div class="text-h5 pa-12 text-center">
            Вы действительно хотите удалить трансляцию?
            <br><br>
            <span v-if="user.admin">{{ selectedEvent.name }}</span>
            <br>
            с {{ selectedEvent.start.toLocaleString() }}
            <br>
            <span style="margin-left: -15px"> до {{ selectedEvent.end.toLocaleString() }} </span>

          </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="deleteFileForm.show = false"
              >Отмена</v-btn>

              <v-btn
                text
                color="red"
                @click="delTrans(selectedEvent.id)"
              >Удалить</v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>


    <div>
    <div id="logo">
      <v-img id="logo_img"
          src="../assets/logo.png"
          max-width="150px"
      ></v-img>
    </div>


  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Сегодня
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

            <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="addFileDialog = true"
          >
            +Добавить
          </v-btn>

          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>День</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Неделя</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Месяц</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'; focus=''">
                <v-list-item-title>4 дня</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet id="timetable">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          locale="ru-RU"
          :events="events"
          interval-height="30"
          :type="type"
          @mouseup:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        >

          <template v-slot:day-body="{ date, week}">
            <div
              class="v-current-time"
              :class="{ first: (date === week[0].date) }"
              :style="{ top: nowY }"
            ></div>
          </template>

        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :activator="selectedElement"
          offset-x
        >
<!--          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-btn icon>
                <v-icon @click="deleteEvent(selectedEvent.id)">mdi-delete</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card>-->

          <v-list>
        <v-list-item link>
          <v-list-item-title v-if="((user.id === selectedEvent.userId || user.admin) && selectedEvent.start > (new Date(Date.now())))"
                             @click="deleteFileForm.show = true"
          >
            <v-icon>mdi-delete</v-icon>Удалить
          </v-list-item-title>
          <v-list-item-title v-else>Нельзя удалять прошедшие трансляции и трансляции других пользователей</v-list-item-title>
        </v-list-item>
      </v-list>

        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>



    </div>

    <AddFileForm id="dialog" @addEvent="setNewEvent" @dialogEvent="addFileDialog = !addFileDialog; getTrans();" :events="events" :addFileDialog="addFileDialog" :date="this.focus"></AddFileForm>

  </v-app>
</template>

<script>

import AddFileForm from "./AddFileForm";
export default {
  name: "ControlPannel",
  components: {AddFileForm},

  computed:{
      cal () {
        return this.ready ? this.$refs.calendar : null
      },
      nowY () {
        return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
      },

    user(){
      return this.$store.getters.getUser;
    }
  },

  methods:{
      getCurrentTime () {
        return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
      },
      scrollToTime () {
        const time = this.getCurrentTime()
        const first = Math.max(0, time - (time % 30) - 30)

        this.cal.scrollToTime(first)
      },

    setEvent(id, userId,start, stop, name){

            let now = new Date(Date.now())

            start = new Date(start);
            stop = new Date(stop)

            let color = 'grey';

            if(start < now && stop > now)
              color = 'pink'

            if(start > now)
              color='green'


            this.events.push({
                id:id,
                userId: userId,
                name: name,
                start: start,
                color: color,
                end: stop,
                timed: true
            })
    },

    setNewEvent(e){
        this.setEvent(e.id,e.start, e.stop);
    },

    delTrans(id){
      this.deleteFileForm.loading = true;

      this.$api.delete('file/'+id).then(r =>{
        if(r.status === 200){
          this.deleteFileForm.delStatus = true;
          this.getTrans();
        }
      })
    },

    getTrans(){

      this.$api.get('file').then(r =>{
        this.events = [];

        if(r.status === 200){
          this.cal.updateTimes();
          let fl = r.data.files;
          for(let i = 0; i < fl.length; i++){
            this.setEvent(fl[i]['id'],fl[i]['user'],fl[i]['start'],fl[i]['stop'],fl[i]['name'])


          }

          if(this.deleteFileForm.show && this.deleteFileForm.loading && this.deleteFileForm.delStatus) {
            this.deleteFileForm = {
              show: false,
              loading: false,
              delStatus: false
            }
          }
        }
      })
    },

    deleteEvent(id, st = false){


      if(st){
        return false
      }
    },

    viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        console.log('Работаю!!', event.id);

        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
  },
  mounted() {
      this.$refs.calendar.checkChange();
      this.getTrans();

      this.ready = true
      this.scrollToTime()

      setInterval(() =>{
        this.getTrans();
      }, 3000)
    },

  data: () =>({
    addFileDialog: false,

    fileForms:[],

    ready: false,

    deleteFileForm: {
      show: false,
      loading: false,
      delStatus: false
    },

    focus: '',
      type: '4day',
      typeToLabel: {
        month: 'Месяц',
        week: 'Недяля',
        day: 'День',
        '4day': '4 Дня',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

  }),
}
</script>

<style scoped lang="scss">
  #content {
    width: 100%;

    #logo{
      position: absolute;
      width: 100%;
      display: block;


      #logo_img{
        z-index: 1;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    #dialog{
      position: absolute;
    }
  }

  #fileForm{
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

    .v-current-time {
      height: 2px;
      background-color: #ea4335;
      position: absolute;
      left: -1px;
      right: 0;
      pointer-events: none;

      &.first::before {
        content: '';
        position: absolute;
        background-color: #ea4335;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
      }
    }

</style>