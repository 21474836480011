var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticStyle:{"min-height":"45px"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.addFileDialog),callback:function ($$v) {_vm.addFileDialog=$$v},expression:"addFileDialog"}},[(_vm.snackbar.visible)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"size":70,"width":3,"color":"white","value":_vm.snackbar.process}},[_vm._v(" "+_vm._s(_vm.snackbar.process)+" ")])],1):_vm._e(),(_vm.error_massage !== '')?_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(_vm._s(_vm.error_massage))]):_vm._e(),_c('v-card',[_c('v-card-title',[_vm._v("Добовление файла")]),_c('form',{attrs:{"id":"fileForm"}},[_c('v-file-input',{attrs:{"label":"Видео в формате mp4","locale":"ru-RU","counter":"","accept":"video/mp4","show-size":"","outlined":"","prepend-icon":"mdi-paperclip"},on:{"change":_vm.getTime},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.fileForm.file),callback:function ($$v) {_vm.$set(_vm.fileForm, "file", $$v)},expression:"fileForm.file"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.dateError},model:{value:(_vm.fileForm.startDate),callback:function ($$v) {_vm.$set(_vm.fileForm, "startDate", $$v)},expression:"fileForm.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"ru-RU","no-title":"","scrollable":""},on:{"input":function($event){return _vm.$refs.menu.save(_vm.fileForm.startDate)}},model:{value:(_vm.fileForm.startDate),callback:function ($$v) {_vm.$set(_vm.fileForm, "startDate", $$v)},expression:"fileForm.startDate"}})],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.fileForm.startTime,"offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.fileForm, "startTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.fileForm, "startTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Время","prepend-icon":"mdi-clock-time-four-outline","messages":_vm.end_time,"error-messages":_vm.timeError !== '' ? _vm.timeError:_vm.busy_error,"readonly":""},model:{value:(_vm.fileForm.startTime),callback:function ($$v) {_vm.$set(_vm.fileForm, "startTime", $$v)},expression:"fileForm.startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"format":"24hr","locale":"ru-RU","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.fileForm.startTime)}},model:{value:(_vm.fileForm.startTime),callback:function ($$v) {_vm.$set(_vm.fileForm, "startTime", $$v)},expression:"fileForm.startTime"}}):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('dialogEvent')}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.validationForm === true},on:{"click":_vm.upload_file}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }